import React from 'react';
import styles from './RoundButton.module.css';

const RoundButton = ({ children, onClick, variant = 'button' }) => {
	if (variant === 'div') {
		return (
			<div
				onClick={onClick}
				className={styles.button}
				type='button'
			>
				{children}
			</div>
		);
	}

	return (
		<button
			onClick={onClick}
			className={styles.button}
			type='button'
		>
			{children}
		</button>
	);
};

export default RoundButton;
