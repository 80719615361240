import React, { useEffect, useState } from 'react';
import styles from './Header.module.css';
import { IconContext } from 'react-icons';

import { BsWhatsapp } from 'react-icons/bs';
import { FiPhone } from 'react-icons/fi';
// import { FiMail } from 'react-icons/fi';
// import { GiHamburgerMenu } from 'react-icons/gi';
import Modal from '../../general/Modal/Modal';
import RoundButton from '../../general/RoundButton/RoundButton';

const navLinks = [
	{
		name: 'Home',
		link: '#',
	},
	{
		name: 'About',
		link: '#about',
	},
	{
		name: 'Services',
		link: '#service',
	},
];

const contactInfo = [
	{
		name: 'WhatsApp',
		href: 'https://api.whatsapp.com/send?phone=971547719428',
		icon: <BsWhatsapp />,
	},
	{
		name: 'Phone',
		href: 'tel:+971547719428',
		icon: <FiPhone />,
	},
	// { name: 'Email', href: 'mailto:info@leprestige.org.uk', icon: <FiMail /> },
];

const Header = () => {
	const [isHeaderTranslucent, setIsHeaderTranslucent] = useState(false);
	const [isMobileNavOpened, setIsMobileNavOpened] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.innerWidth <= 600) return;
			if (
				document.body.scrollTop > 50 ||
				document.documentElement.scrollTop > 50
			) {
				setIsHeaderTranslucent(true);
			} else {
				setIsHeaderTranslucent(false);
			}
		});

		return () => {
			if (window.innerWidth <= 600) return;
			window.removeEventListener('scroll', () => {
				if (
					document.body.scrollTop > 50 ||
					document.documentElement.scrollTop > 50
				) {
					setIsHeaderTranslucent(true);
				} else {
					setIsHeaderTranslucent(false);
				}
			});
		};
	});

	const toggleMobileNavOpen = () => {
		setIsMobileNavOpened((prev) => !prev);
	};

	const navBar = (
		<nav className={styles.navbar}>
			<ul>
				{navLinks.map((link, i) => {
					return (
						<li key={i}>
							<a href={link.link}>{link.name}</a>
						</li>
					);
				})}
			</ul>
		</nav>
	);

	const mobileNav = (
		<nav className={styles['mobile-nav']}>
			<ul>
				{navLinks.map((link, i) => {
					return (
						<li
							key={i}
							onClick={toggleMobileNavOpen}
						>
							<a href={link.link}>{link.name}</a>
						</li>
					);
				})}
			</ul>
		</nav>
	);

	return (
		<>
			<header className={styles.header}>
				<div
					className={styles.content}
					style={{ height: isHeaderTranslucent && 100 }}
				>
					<div className={styles.menu}>
						{navBar}
						{/* <div className={styles['menu-button']}>
							<RoundButton onClick={toggleMobileNavOpen}>
								<div className={styles['menu-icon']}>
									<GiHamburgerMenu
										style={{
											color: 'white',
											fontSize: 26,
											display: 'block',
										}}
									/>
								</div>
							</RoundButton>
						</div> */}
					</div>
					<div
						className={styles.logo}
						style={{ maxWidth: isHeaderTranslucent && 80 }}
					>
						<a href='#'>
							<img
								src='/image/logo.png'
								alt='logo'
							/>
						</a>
					</div>
					<address className={styles.contact}>
						<IconContext.Provider
							value={{
								style: {
									color: '#fff',
									fontSize: 25,
									display: 'block',
								},
							}}
						>
							{contactInfo.map((info, i) => {
								return (
									<React.Fragment key={i}>
										{i > 0 && (
											<p className={styles.divider}></p>
										)}
										<RoundButton
											variant='div'
											key={i}
										>
											<a href={info.href}>{info.icon}</a>
										</RoundButton>
									</React.Fragment>
								);
							})}
						</IconContext.Provider>
					</address>
				</div>
			</header>
			<div className={styles['mobile-menu']}>
				<Modal
					open={isMobileNavOpened}
					handleClose={toggleMobileNavOpen}
					plain
					clickAwayDisabled
					closeButtonRight
				>
					{mobileNav}
				</Modal>
			</div>
		</>
	);
};

export default Header;
