import React from 'react';
import styles from './MainButton.module.css';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const MainButton = ({ text, onClick, type = 'text', loading }) => {
	return (
		<div
			className={styles.container}
			onClick={onClick}
		>
			<button
				className={styles.button}
				type={type}
				style={{ color: loading && 'transparent' }}
			>
				{loading && <LoadingSpinner color='#a98517' />}

				{text}
			</button>
		</div>
	);
};

export default MainButton;
