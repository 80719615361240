import Reactfrom from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import styles from './BlackButton.module.css';

const BlackButton = ({ text, onClick, type = 'text', loading }) => {
	return (
		<button
			onClick={onClick}
			className={styles.button}
			type={type}
			style={{
				color: loading && 'transparent',
			}}
		>
			{loading && <LoadingSpinner />}
			{text}
		</button>
	);
};

export default BlackButton;
