import React from "react";
import Banner from "../../component/home/Banner/Banner";
import Service from '../../component/home/Service/Service';
import Review from "../../component/home/Review/Review";
import Expertise from "../../component/home/Expertise/Expertise";
import WhyUs from "../../component/home/WhyUs/WhyUs";

const Home = ({ handleConsultationFormOpen, handleInfoFormOpen }) => {

  return (
    <div>
      <Banner handleConsultationFormOpen={handleConsultationFormOpen} handleInfoFormOpen={handleInfoFormOpen} />
      <Service />
      <Expertise />
      <Review />
      <WhyUs />
    </div>
  );
};

export default Home;
