import React from 'react';
import styles from './Service.module.css';

const services = [
	{
		title: 'Pro-immigration',
		text: `Canada has a reputation as one of the most 
		welcoming countries for immigrants. Because of its 
		vast landmass and small population, it is also one of 
		the easiest countries to immigrate to.`,
		icon: '/image/family.png',
		alt: 'family icon',
	},
	{
		title: 'Free Healthcare',
		text: `Canada provides free basic health care to all citizens based on need rather than the ability to pay. It is paid for by Canadian citizens who contribute to the system through taxes.`,
		icon: '/image/plus.png',
		alt: 'Medical aid icon',
	},
	{
		title: 'Safety & Freedom',
		text: `Canada provides free basic health care to all citizens based on need rather than the ability to pay. It is paid for by Canadian citizens who contribute to the system through taxes.`,
		icon: 'image/shield.png',
		alt: 'shield icon',
	},
	{
		title: 'Free Education',
		text: `Children of Canadian citizens, permanent residents, refugees, asylum seekers, and other documented immigrants are entitled to free education in Canada.`,
		icon: 'image/graduation-hat.png',
		alt: 'graduation hat icon',
	},
];

const Service = () => {
	return (
		<div
			className={styles.service}
			id='benefits'
		>
			<div className={styles['service-content']}>
				<p className={styles['title-over']}>
					Benefits of living in Canada
				</p>
				<div className={styles['service-left']}>
					<h2>
						For those who are seeking a{' '}
						<span className={styles.underline}>better life</span>
					</h2>
				</div>
				<div className={styles['service-right']}>
					{services.map((service, i) => {
						return (
							<div
								key={i}
								className={styles['service-item']}
							>
								<div>
									<div className={styles.icon}>
										<img
											src={service.icon}
											alt={service.alt}
										/>
									</div>
								</div>
								<p
									className={`${styles['subtitle-weight']} subtitle`}
								>
									{service.title}
								</p>
								<p>{service.text}</p>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Service;
