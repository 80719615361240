import React from 'react';
import Accordion from '../../general/Accordion/Accordion';
// import './Expertise.css';
import styles from './Expertise.module.css';

const accordionContent = [
	{
		heading: 'Start-up Visa',
		content: (
			<div className={styles['accordition-content']}>
				<p>
					The Canada Start-up Visa program, also referred to as the
					SUV is one of the most popular Business Immigration routes
					that allows entrepreneurs to set up a Business in Canada and
					get Canadian Residency and Citizenship in return.
				</p>
				<p>
					The Canada Start-up Visa allows applicants to get{' '}
					<b>Canadian Permanent Residency (PR) Directly</b> and apply
					for <b>Canadian Citizenship in just 3 years</b>.
				</p>
				<p>
					<b>Excellent Alternative to Express Entry and PNP</b>
				</p>
				<p>
					The Canada Start-up Visa has no CRS Score requirement, no
					age requirements and allows applicants with a low IELTS
					score (CLB 5) to apply, making it an excellent alternative
					to the highly competitive Express Entry and PNP programs.
				</p>
			</div>
		),
	},
];

const Expertise = () => {
	return (
		<div
			id='service'
			className={styles.expertise}
		>
			<div className={styles['expertise-left']}>
				<div className={styles['img-container']}>
					<img
						src='/image/Good-night--Vancouver.jpg'
						alt='united-kingdom-night'
					/>
					<div className={styles['text-over-img']}>
						<p>Le Prestige Immigration is an</p>
						<div>
							<p>
								International Immigration Law firm with Office
								in London, Toronto and Dubai
							</p>
							<span></span>
						</div>
					</div>
				</div>
			</div>
			<div className={styles['expertise-right']}>
				<div>
					<p className={styles['title-over']}>Business Immigration</p>
					<h2>Le Prestige expertise</h2>
				</div>
				<div>
					{accordionContent.map((accordionItem, i) => {
						return (
							<Accordion
								key={i}
								heading={accordionItem.heading}
								content={accordionItem.content}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Expertise;
