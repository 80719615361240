import React, { useState } from 'react';
import MainButton from '../../general/MainButton/MainButton';
import styles from './ConsultationForm.module.css';
import Modal from '../../general/Modal/Modal';
import Form from '../../general/Form/Form';
import TextInput from '../../general/TextInput/TextInput';
import SelectInput from '../../general/SelectInput/SelectInput';
import countriesData from '../../../vars/countries';

const nationalityOptions = () => {
	const nationatities = countriesData.map((country) => {
		return { name: country.nationality, value: country.nationality };
	});
	return [{ name: '-', value: '' }, ...nationatities];
};

const countryOptions = () => {
	const countries = countriesData.map((country) => {
		return {
			name: country['en_short_name'],
			value: country['en_short_name'],
		};
	});
	return [{ name: '-', value: '' }, ...countries];
};

const formInputs = [
	{
		id: 'name',
		name: 'name',
		placeholder: 'David',
		label: 'Name',
		required: true,
		type: 'text',
	},
	{
		id: 'surname',
		name: 'surname',
		placeholder: 'Mahit',
		label: 'Surname',
		required: true,
		type: 'text',
	},
	{
		id: 'email',
		name: 'email',
		placeholder: 'david.mahit@gmail.com',
		label: 'Email',
		required: true,
		type: 'email',
	},
	{
		id: 'phone',
		name: 'phone',
		placeholder: '+971 XXX XXX XXX',
		label: 'Phone',
		required: true,
		type: 'tel',
	},
	{
		id: 'nationality',
		name: 'nationality',
		label: 'Nationality',
		required: true,
		type: 'select',
		options: nationalityOptions(),
	},
	{
		id: 'country',
		name: 'country',
		label: 'Country of Residence',
		required: true,
		type: 'select',
		options: countryOptions(),
	},
	{
		id: 'job_title',
		name: 'job_title',
		placeholder: 'CEO',
		label: 'Job Title',
		required: true,
		type: 'text',
	},
	{
		id: 'earning',
		name: 'earning',
		label: 'Net Earning Per Month (AED)',
		required: true,
		type: 'select',
		options: [
			{ name: '-', value: '' },
			{ name: '5000-15000', value: '5000-15000' },
			{ name: '15000-20000', value: '15000-20000' },
			{ name: '20000-25000', value: '20000-25000' },
			{ name: '25000-30000', value: '25000-30000' },
			{ name: '30000-35000', value: '30000-35000' },
			{ name: '35000-40000', value: '35000-40000' },
			{ name: '40000-45000', value: '40000-45000' },
			{ name: '45000-50000', value: '45000-50000' },
			{ name: '50000+', value: '50000+' },
		],
	},
];

const ConsultationForm = ({ open, handleClose }) => {
	const [fetchStatus, setFetchStatus] = useState('');
	const [isButtonLoading, setIsButtonLoading] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		setIsButtonLoading(true);

		const formData = {
			_subject: `UK - ${e.target.name.value} booked a consultation`,
			Name: e.target.name.value,
			Surname: e.target.surname.value,
			Email: e.target.email.value,
			Phone: e.target.phone.value,
			Nationality: e.target.nationality.value,
			Country: e.target.country.value,
			'Job Title': e.target.job_title.value,
			'Net Earning Per Month (AED)': e.target.earning.value,
		};

		fetch('https://formspree.io/f/meqdlwwv', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
			},
			body: JSON.stringify(formData),
		})
			.then((response) => {
				if (response.ok) {
					setFetchStatus(
						'Thank you for taking the time to contact us. We will be in touch shortly.'
					);
				} else {
					response.json().then((data) => {
						if (Object.hasOwn(data, 'errors')) {
							setFetchStatus(
								data['errors']
									.map((error) => error['message'])
									.join(', ')
							);
						} else {
							setFetchStatus(
								'Something went wrong. Please try again.'
							);
						}
					});
				}
			})
			.catch((error) => {
				console.log(error);
				setFetchStatus('Something went wrong. Please try again.');
			});
	};

	const form = (
		<form
			onSubmit={handleSubmit}
			className={styles['form-container']}
		>
			<div className={styles['inputs-container']}>
				{formInputs.map((input) => {
					if (input.type === 'select') {
						return (
							<div
								key={input.id}
								className={styles['input-item']}
							>
								<SelectInput
									id={input.id}
									name={input.name}
									label={input.label}
									required={input.required}
									options={input.options}
								/>
							</div>
						);
					}
					return (
						<div
							key={input.id}
							className={styles['input-item']}
						>
							<TextInput
								id={input.id}
								name={input.name}
								placeholder={input.placeholder}
								label={input.label}
								type={input.type}
								required={input.required}
							/>
						</div>
					);
				})}
			</div>
			<MainButton
				text='Book a consultation'
				type='submit'
				loading={isButtonLoading}
			/>
		</form>
	);

	return (
		<Modal
			open={open}
			handleClose={handleClose}
		>
			<Form
				bgImage='/image/bg-consultations.jpg'
				heading='Schedule a personal consultation with the best immigration law firm'
			>
				{fetchStatus || form}
			</Form>
		</Modal>
	);
};

export default ConsultationForm;
