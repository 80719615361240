import React from 'react';
import styles from './SelectInput.module.css';

const SelectInput = ({ name, id, label, required, options }) => {
	return (
		<div className={styles.container}>
			<label
				className={styles.label}
				htmlFor={id}
			>
				{label}
			</label>

			<select
				id={id}
				name={name}
				required={required}
			>
				{options.map((option, i) => {
					return (
						<option
							key={i}
							value={option.value}
						>
							{option.name}
						</option>
					);
				})}
			</select>
		</div>
	);
};

export default SelectInput;
