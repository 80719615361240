import React from 'react';
import styles from './LoadingSpinner.module.css';

const LoadingSpinner = ({ color }) => {
	return (
		<div className={styles.container}>
			<div
				style={{ borderColor: color, borderBottomColor: 'transparent' }}
				className={styles.loader}
			></div>
		</div>
	);
};

export default LoadingSpinner;
