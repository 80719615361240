import React from 'react';
import styles from './TextInput.module.css';

const TextInput = ({ name, id, label, placeholder, required, type }) => {
	return (
		<div className={styles.container}>
			<label
				className={styles.label}
				htmlFor={id}
			>
				{label}
			</label>

			<input
				type={type}
				id={id}
				name={name}
				placeholder={placeholder}
				required={required}
			/>
		</div>
	);
};

export default TextInput;
