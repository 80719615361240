import React from 'react';
import styles from './Review.module.css';

import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import styles from './VideoSwiper.module.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const reviewData = [
	{
		quote: `I would like to thank Le Prestige and their professional team in Dubai & Canada for all their support.`,
		name: 'Asma Saidi',
		position: 'Head of Business Operations',
		image: '/image/Asma Saidi.png',
		video: 'eh2-AhIb4n4',
	},
	{
		quote: `I highly recommend Le Prestige Immigration for anyone looking to relocate to Canada.`,
		name: 'Muhammad Sangare',
		position: 'CEO',
		image: '/image/Muhammad Sanagre.jpg',
		video: 'AF6CLluJDiY',
	},
	{
		quote: `I advise everyone to go with this firm as they are very professional and have a lot of experience.`,
		name: 'Ali Abdullah',
		position: 'Business Owner',
		image: '/image/Ali Abdullah.jpg',
		video: 'KHxwjIYDdcQ',
	},
];

const Review = () => {
	return (
		<div
			className={styles.review}
			id='testimonials'
		>
			<Swiper
				modules={[Navigation, Pagination, A11y]}
				spaceBetween={20}
				slidesPerView={1}
				navigation
				pagination={{ clickable: true }}
				updateOnWindowResize={true}
			>
				{reviewData.map((review, i) => {
					return (
						<SwiperSlide key={i}>
							<div className={styles['review-content']}>
								<div className={styles['review-left']}>
									<div className={styles['review-container']}>
										<p className={styles.quote}>
											<span
												className={
													styles['squote-mark-open']
												}
											>
												{' '}
												“
											</span>
											{review.quote}
											<span
												className={
													styles['squote-mark-close']
												}
											>
												”
											</span>
										</p>
										<div className={styles['person-data']}>
											<div className={styles.avatar}>
												<img
													src={review.image}
													alt='user'
												/>
											</div>
											<div>
												<p className='base1'>
													{review.name}
												</p>
												<p className='base2'>
													{review.position}
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className={styles['review-right']}>
									<div className={styles.slide}>
										<iframe
											listType='playlist'
											rel={0}
											className={styles.iframe}
											frameBorder={0}
											src={`https://www.youtube.com/embed/${review.video}`}
										></iframe>
									</div>
								</div>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default Review;
